import { graphql, Link } from 'gatsby';
import { createUseStyles } from 'react-jss';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import BookNowButton from '../components/BookNowButton';
import Carousel from '../components/Carousel';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const accommodationsOrder = [
  'motel',
  'hostel',
  'casitas',
  'chalet',
];

const useStyles = createUseStyles((theme) => ({
  aboutUs: {
    flex: '1',
  },
  accommodation: {
    display: 'flex',
    flex: '1 1 20%',
    flexDirection: 'column',
    margin: '0.5rem',
    minWidth: '200px',
  },
  accommodationsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  accommodationDescription: {
    flex: '1',
    marginBottom: '1rem',
  },
  accommodationTitle: {
    color: 'black',
    display: 'block',
    fontSize: theme.fontSizeLarge,
    marginBottom: '1rem',
    marginTop: '1rem',
    textAlign: 'center',
  },
  headerAccommodations: {
    marginTop: '3rem',
    textAlign: 'center',
  },
  lowerContainer: {
    display: 'flex',
  },
}));

const Index = (props) => {
  const classes = useStyles(props);
  const {
    data,
  } = props;

  const imagesSortted = data.miscJson.images.sort((a, b) => {
    const aIndex = data.miscJson.imageNames.indexOf(a.fluid.originalName);
    const bIndex = data.miscJson.imageNames.indexOf(b.fluid.originalName);
    return aIndex - bIndex;
  });

  const imageComponents = imagesSortted.map((node, i) => (
    <Img alt={data.miscJson.imageLabels[i]} fluid={node.fluid} />
  ));

  // Add room groups
  const accommodations = [...data.allRoomGroupsJson.nodes];
  // Add chalet
  accommodations.push(data.roomsJson);

  accommodations.sort((a, b) => (
    accommodationsOrder.indexOf(a.propertyType) - accommodationsOrder.indexOf(b.propertyType)
  ));


  return (
    <Layout>
      <SEO title="Home" />
      <Carousel
        images={imageComponents}
      />
      <div>
        <h2 className={classes.headerAccommodations}>
          Accommodations
        </h2>
        <div className={classes.accommodationsContainer}>
          {accommodations.map((accommodation) => {
            const image = accommodation.images.find((img) => (
              img.fluid.originalName === accommodation.imageNames[0]
            ));
            return (
              <div className={classes.accommodation} key={accommodation.propertyType}>
                <Link alt={accommodation.title} to={accommodation.fields.slug}>
                  <Img alt={accommodation.title} fluid={image.fluid} />
                </Link>
                <Link className={classes.accommodationTitle} to={accommodation.fields.slug}>
                  {accommodation.title}
                </Link>
                <div className={classes.accommodationDescription}>
                  {accommodation.description}
                </div>
                <BookNowButton propertyType={accommodation.propertyType} />
              </div>
            );
          })}
        </div>
      </div>
      <hr />
      <div className={classes.lowerContainer}>
        <div className={classes.aboutUs}>
          <h2>
            About Us
          </h2>
          <p>
            Holiday Haus is located where the action is in Mammoth Lakes.
            We are a short 5 minute walk to the Village at Mammoth where
            you will find restaurants, shopping, mountain biking and of
            course mountain access via the Village Gondola for skiing and
            snowboarding.
          </p>
          <p>
            During the summer months, we are in close proximity to the
            many music venues, including Bluesapalooza and other Village events.
          </p>
          <p>
            If you want easy cross-country skiing, mountain biking with
            minimal hills or walking trails, a trail for all is accessible
            via our property.
          </p>
          <p>
            As a recently updated property with a variety of room types and
            price points, we welcome you to come and stay with us for some
            fun in the mountains. We will also provide advice on things to
            do and will book your activities with no commissions or fees (back to us).
          </p>
          <p>
            For instance…have you been on a horse with your family and friends?
            Don’t miss out on an opportunity to ride horseback on an easy,
            fun and beautiful trail ride from Red’s Meadow to Rainbow Falls.
          </p>
          <p>
            We hope to you see you soon!
          </p>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    miscJson {
      imageNames
      imageLabels
      images {
        fluid {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
    }
    allRoomGroupsJson {
      nodes {
        description
        fields {
          slug
        }
        imageNames
        imageLabels
        images {
          fluid {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
        propertyType
        title
      }
    }
    roomsJson(propertyType: { eq: "chalet" }) {
      description
      fields {
        slug
      }
      imageNames
      imageLabels
      images {
        fluid {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
      propertyType
      title
    }
  }
`;

Index.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default Index;
